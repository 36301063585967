<template>
  <div
    :class="isNavLinkActive(menuItem) ? 'active' : ''"
    class="text-left pb-50"
  >
    <router-link :to="menuItem.route ? menuItem.route : ''">
      {{ $t(menuItem.title) }}
    </router-link>
  </div>
</template>

<script>
import { isNavLinkActive } from "@core/layouts/utils";

export default {
  name: "TileItem",
  props: ["menuItem"],
  setup() {
    return {
      isNavLinkActive,
    };
  },
};
</script>

<style lang="scss" scoped>
a:hover {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-bottom: 3px;
}

//@import "src/assets/scss/project-jsf/jsf";
//.box:hover {
//  cursor: pointer;
//  & .border-line {
//    width: 100%;
//    bottom: -4px;
//    position: absolute;
//    border-bottom-width: 2px;
//    border-bottom-style: solid;
//    padding-bottom: 6px;
//  }
//}
//.span {
//  white-space: normal;
//  word-wrap: break-word;
//  word-break: keep-all;
//}
</style>
