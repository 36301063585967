<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <template v-if="isNavMenuHidden">
            <ul class="nav navbar-nav pr-1 mr-1 borderRight" style="border-radius: 0" :style="'border-right:1px solid ' + (isDark ? '#ebe9f1' : '#3b4253')
                ">
                <li>
                    <!-- <b-link v-b-toggle.app-sidebar-menu class="nav-link">
                        <feather-icon icon="MenuIcon" size="21" />
                    </b-link> -->

                    <b-button variant="flat-primary" class="btn-icon rounded-circle" v-b-toggle.app-sidebar-menu
                        v-b-tooltip.hover.v-primary title="导航">
                        <b-icon-list></b-icon-list>
                    </b-button>
                </li>

                <!-- <li>
                    <b-button
                        variant="flat-primary"
                        class="btn-icon rounded-circle"
                        v-b-tooltip.hover.v-primary
                        title="开始"
                        v-b-modal:all-apps-modal
                    >
                        <b-icon-app-indicator />
                    </b-button>
                    <all-apps />
                </li> -->

                <!-- <li>
                    <b-button
                        variant="flat-primary"
                        class="btn-icon rounded-circle"
                        @click="toggleSidebarMenu"
                        v-b-tooltip.hover.v-primary
                        title="导航"
                    >
                        <b-icon-list></b-icon-list>
                    </b-button>
                </li> -->

                <li>
                    <b-button variant="flat-primary" class="btn-icon rounded-circle" @click="handleRouter('back')"
                        v-b-tooltip.hover.v-primary title="返回">
                        <b-icon-arrow-left></b-icon-arrow-left>
                    </b-button>
                </li>
                <li>
                    <b-button variant="flat-primary" @click="handleRouter('refresh')" class="btn-icon rounded-circle"
                        v-b-tooltip.hover.v-primary title="刷新">
                        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
                    </b-button>
                </li>

                <li>
                    <b-button v-if="$route.path !== '/project-list'" variant="flat-primary"
                        class="btn-icon rounded-circle" to="/project-list" v-b-tooltip.hover.v-primary title="首页">
                        <b-icon-pin-map-fill></b-icon-pin-map-fill>
                    </b-button>
                </li>

                <!--        <li class="nav-item">-->
                <!--          <b-link class="nav-link" @click="toggleSidebarMenu">-->
                <!--            <feather-icon icon="MenuIcon" size="21"/>-->
                <!--          </b-link>-->
                <!--        </li>-->
            </ul>
        </template>

        <!--      <ul-->
        <!--        :style="'border-right:1px solid ' + (isDark ? '#d0d2d6' : '#6e6b7b')"-->
        <!--        class="nav navbar-nav pr-1 mr-1"-->
        <!--        style="border-radius: 0; border: 2px solid red"-->
        <!--      >-->
        <!--        &lt;!&ndash; Animated div &ndash;&gt;-->
        <!--      </ul>-->

        <Transition name="dropdown">
            <div v-if="showToggle" @mouseleave="showToggle = false" class="sidebar-menu b-sidebar bg-white text-dark"
                style="
                    height: calc(100vh - 60px);
                    max-height: calc(100vh - 60px);
                ">
                <!-- Add your content here -->
                <!--        <p>This is the sidebar menu content</p>-->
                <!-- 遍历数据 -->
                <tiles-menu :menu-tree="menuTree" style="height: 100%; width: 100%"></tiles-menu>
            </div>
        </Transition>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <div class="h-100 d-flex position-relative">
                <!--        <div v-if="project_id===74" class="h-100 mr-50">-->
                <!--          <b-img src="/images/cabr-logo.png" style="width: 2.4rem;height: 2rem"></b-img>-->
                <!--        </div>-->
                <app-breadcrumb :isDark="isDark" class="h-100 mx-50" />
            </div>
            <!-- Bookmarks Container -->
            <!-- <bookmarks />-->
            <!--	     <h2>{{$store.state.appConfig.app.appName}}</h2>-->
        </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <chat-gpt />
            <locale />
            <!-- <theme-toggler /> -->
            <dark-toggler />
            <user-dropdown />
        </b-navbar-nav>
    </div>
</template>

<script>
// import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import ThemeToggler from "./components/ThemeToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import ChatGPT from "./components/ChatGpt.vue";
import AllApps from "@/views/layout/app-navbar/AllApps";
import useAppConfig from "@core/app-config/useAppConfig";
import TilesMenu from "@/views/layout/app-sidebar-menu/components/TilesMenu";
import {
    computed,
    onMounted,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api";
import store from "@/store";
import { routerParams } from "@/libs/utils/routerParams";
import router from "@/router";
import ChatGpt from "@core/layouts/components/app-navbar/components/ChatGpt.vue";

export default {
    components: {
        ChatGpt,
        AppBreadcrumb,
        // Navbar Components
        // Bookmarks,
        ChatGPT,
        Locale,
        SearchBar,
        DarkToggler,
        ThemeToggler,
        CartDropdown,
        NotificationDropdown,
        UserDropdown,
        AllApps,
        TilesMenu,
    },

    props: {
        toggleSidebarMenuActive: {
            type: Function,
            default: () => { },
        },
    },
    setup() {
        const { isNavMenuHidden, skin } = useAppConfig();

        const menuFormat = (menus_tree = []) => {
            let menus = [];
            if (menus_tree.length > 0) {
                menus = [
                    {
                        title: "总览",
                        icon: "",
                        menu_id: -99,
                        p_menu_id: 0,
                        children: menus_tree,
                    },
                    {
                        header: "项目导航",
                    },
                    ...menus_tree,
                    //管理页面
                    {
                        header: "System Manage",
                        menu_id: -1,
                    },
                    {
                        title: "System Settings",
                        icon: "SettingsIcon",
                        menu_id: -1,
                        p_menu_id: 0,
                        children: [
                            {
                                title: "Users Manage",
                                route: {
                                    name: "admin-users-list",
                                    params: {
                                        title: "Users Manage",
                                        icon: "UserIcon",
                                        levels: [
                                            {
                                                title: "System Manage",
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                title: "Projects Manage",
                                route: {
                                    name: "admin-projects-list",
                                    params: {
                                        title: "Projects Manage",
                                        icon: "GridIcon",
                                        levels: [
                                            {
                                                title: "System Manage",
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                title: "Menus Manage",
                                route: {
                                    name: "admin-menus-list",
                                    params: {
                                        title: "Menus Manage",
                                        icon: "MenuIcon",
                                        levels: [
                                            {
                                                title: "System Manage",
                                            },
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                ];
            }
            return [
                //前端页面
                // {
                //     title: "Project List",
                //     icon: "MapIcon",
                //     route: "index-project-list",
                // },
                ...menus,
                {
                    title: "Account Settings",
                    icon: "UserIcon",
                    menu_id: -1,
                    p_menu_id: 0,
                    route: {
                        name: "admin-account-setting",
                        icon: "UserIcon",
                        params: {
                            title: "Account Settings",
                        },
                    },
                },
                /* ...vuexy_demo_navigation,  */
            ];
        };
        const event = reactive({
            router: computed(() => {
                return store.state.menus.current.name;
            }),
            project_id: computed(() => {
                return parseInt(routerParams("project_id"));
            }),
            isDark: computed(() => {
                return skin === "dark";
            }),
            show_project_list: true,
            menuTree: computed(() => {
                return menuFormat(store.state.menus.tree);
            }),
            showToggle: false,
        });

        watch(
            () => store.state.menus.current.name,
            (route) => {
                // console.log('show_project_list',((!route) || (route === 'index-project-list')))
                event.show_project_list =
                    !route || route === "index-project-list";
            }
        );

        const toggleSidebarMenu = () => {
            event.showToggle = !event.showToggle;
        };
        onMounted(() => {
            event.show_project_list =
                !event.router || event.router === "index-project-list";
        });

        const handleRouter = (type) => {
            switch (type) {
                case "refresh":
                    window.location.reload();
                    return;
                case "back":
                    router.back();
                    return;
            }
        };
        return {
            ...toRefs(event),
            isNavMenuHidden,
            handleRouter,
            toggleSidebarMenu,
        };
    },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/core/colors/palette-variables.scss";

.slide-down-enter-active,
.slide-down-leave-active {
    transition: all 0.5s;
}

.slide-down-enter,
.slide-down-leave-to {
    transform: translateY(-100%);
}

.sidebar-menu {
    position: absolute;
    //background-color: white;
    left: 0;
    top: 60px;
    bottom: 0;
    width: 100%;
    padding: 20px;
    //border: 1px solid #ccc;
}

.dropdown-enter-active {
    transition: opacity 0.2s;
}

.dropdown-enter

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

.dropdown-leave {
    opacity: 1;
}

.dropdown-leave-active {
    opacity: 0;
    transition: opacity 0.2s;
}

/* Lucas */
.borderRight {
    border-right: 1px solid #ebe9f1 !important;
}

.dark-layout .borderRight {
    border-right: 1px solid #3b4253 !important;
}
</style>
