<template>
    <b-nav-item v-show="isShow" @click="themeActive = !themeActive">
        <p class="m-0">
            <feather-icon
                :icon="themeActive ? 'ToggleRightIcon' : 'ToggleLeftIcon'"
                size="21"
            />
        </p>
    </b-nav-item>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import {
    computed,
    onMounted,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api";
import { BNavItem } from "bootstrap-vue";
import { routerParams } from "@/libs/utils/routerParams";

export default {
    components: {
        BNavItem,
    },
    setup() {
        const { skin } = useAppConfig();
        const event = reactive({
            project_id: computed(() => {
                return routerParams("project_id");
            }),
            themeList: {
                72: "jsf_theme",
            },
            themeCurrent: computed(() => {
                return event.themeList[event.project_id] || "";
            }),
            themeActive: false,
            isShow: computed(() => {
                return Object.keys(event.themeList).includes(
                    event.project_id + ""
                );
            }),
        });
        watch(
            () => event.project_id,
            () => {
                initTheme();
                if (!event.isShow) {
                    document.body.setAttribute("data-theme", "");
                }
            }
        );
        watch(
            () => skin.value,
            () => {
                event.themeActive = false;
            }
        );
        watch(
            () => event.themeActive,
            () => {
                setTheme();
            }
        );
        // 设置主题
        const setTheme = () => {
            let theme = event.themeCurrent;
            let themeActive = event.themeActive;
            if (theme) {
                document.body.setAttribute(
                    "data-theme",
                    themeActive ? theme : ""
                );
                localStorage.setItem(theme, themeActive);
                console.log("设置主题：", theme);
            }
        };

        //初始化主题
        const initTheme = () => {
            let themeCurrent = event.themeCurrent;
            if (!!themeCurrent) {
                let themeActive = localStorage.getItem(themeCurrent);
                event.themeActive = themeActive === "true";
                setTheme();
            }
        };

        onMounted(() => {
            initTheme();
        });
        return {
            ...toRefs(event),
        };
    },
};
</script>
