<template>
  <b-button
		v-b-tooltip.hover
		:class="isNavLinkActive(menuItem) ? 'active' : ''"
		:title="$t(menuItem.title)"
		:to="menuItem.route ? menuItem.route : ''"
		class="bg-light-secondary w-100 p-1 text-left d-flex flex-column justify-content-between"
		style="height: 6rem;"
		variant="flat-primary"
	>
		<feather-icon :icon="menuItem.icon || 'GridIcon'" size="18" />

		<div class="w-100 text-nowrap text-truncate">
			{{ $t(menuItem.title) }}
		</div>
	</b-button>
  <!-- <span
    v-b-tooltip.hover
    :class="isNavLinkActive(menuItem) ? 'active' : ''"
    :title="$t(menuItem.title)"
    @click="toPage(menuItem.route)"
  >
    {{ $t(menuItem.title) }}
  </span> -->
</template>

<script>
import { isNavLinkActive } from "@core/layouts/utils";
import router from "@/router";

export default {
  name: "TileItem",
  props: ["menuItem"],
  setup() {
	const toPage = (route)=>{
		// console.log("导航",route)
		if(route !== ''){
			// console.log("route",route)
			router.push(route)
		}

	}
    return {
      isNavLinkActive,
	  toPage
    };
  },
};
</script>

<style scoped></style>
